'use strict';

const clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

module.exports = function () {
    var subcategoryList = $('.js-subcategory');

    if ($(window).width() > 992) {
        $('.js-title .title-type').on('mouseover', function () {
            var elementId = $(this).attr('id');
            $('.js-title').removeClass('category-hover category-pro-hover');
            $(this).parent().closest('.dropdown-menu').hasClass('pro-bg') ? $(this).parent().addClass('category-pro-hover') : $(this).parent().addClass('category-hover');
            subcategoryList.addClass('d-none');
            subcategoryList.each(function () {
                var subcategory = $(this);
                if (subcategory.attr('data-id') == elementId) {
                    subcategory.removeClass('d-none');
                }
            });
        });
    } else {
        $('.js-title').on('click', function () {
            var elementId = $(this).find('.title-type').attr('id');
            if (elementId != 'demande_devis') {
                subcategoryList.addClass('d-none');
                subcategoryList.each(function () {
                    var subcategory = $(this);
                    if (subcategory.attr('data-id') == elementId) {
                        subcategory.removeClass('d-none');
                    }
                });
                $('.subcategories-wrapper').addClass('show-products');
            }
        });

        $('.navbar-nav').on('click', '.back', function (e) {
            e.preventDefault();
            if ($('.subcategories-wrapper').hasClass('show-products')) {
                $('.subcategories-wrapper').removeClass('show-products');
            } else {
                clearSelection(this);
            }
        });
    }

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]').on('mouseenter', function () {
        var $dropdownMenu = $(this).siblings('.dropdown-menu');
        var $firstSubcategory = $dropdownMenu.find('.js-subcategory:first');

        $dropdownMenu.find(subcategoryList).not($firstSubcategory).addClass('d-none');
        $firstSubcategory.removeClass('d-none');
        $dropdownMenu.find('.js-title').removeClass($dropdownMenu.find('.js-title').closest('.pro-bg').length ? 'category-pro-hover' : 'category-hover');
        $dropdownMenu.find('.js-title:first').addClass($dropdownMenu.find('.js-title:first').closest('.pro-bg').length ? 'category-pro-hover' : 'category-hover');
    });

    $(window).on('load', function () {
        $(subcategoryList).addClass('d-none');
        $('.subcategories-container').each(function () {
            var $title = $(this).find('.js-title:first');
            $title.addClass($title.closest('.pro-bg').length ? 'category-pro-hover' : 'category-hover');
        });
        $('.content-container').each(function () {
            var $content = $(this).find('.js-subcategory:first');
            $content.removeClass('d-none');
        });
    });
};
