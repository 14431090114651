'use strict';

var scrollAnimate = require('base/components/scrollAnimate');
var Gtm = require('../components/gtm');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

module.exports = function () {
    $('.back-to-top').on('click', function () {
        scrollAnimate();
    });

    $('.footer-heading .title').on('click', function (event) {
        $('.footer-item').not($(this).closest('.footer-item')).removeClass('active');
    });

    $('body').on('click', '.subscribe-email', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $(this).parent('form').find('input[name=hpEmailSignUp]').val();
        let isPopinNewsletter = $('.popin-newsletter_confirm').is(e.target);
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                displayMessage(data, button);
                if (!data.error) {
                    if (isPopinNewsletter) {
                        $('body').trigger('popin:successSubscribe');
                    }
                    Gtm.eventNewOptin(Gtm.NEW_OPTIN_SOURCE.FOOTER);

                    dataLayer.push({
                        event: 'formComplete',
                        eventCategory: 'form',
                        eventLabel: 'newsletter'
                    });
                }
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    $('body').on('click', '[data-event-click="footer"]', (e) => {
        if (e.currentTarget.dataset.url === '') return;

        if (e.currentTarget.getAttribute('target')) {
            window.open(atob(e.currentTarget.dataset.url), '_blank');
        } else {
            window.location.href = atob(e.currentTarget.dataset.url);
        }
    });
};
