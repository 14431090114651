'use strict';

const $popinNewsletter = $('.popin-newsletter');
const Gtm = require('./gtm');

function _getNumberOfPreviousVisits() {
    const numberOfVisits = window.sessionStorage.numberOfVisits ? parseInt(window.sessionStorage.numberOfVisits, 10) + 1 : 0;
    window.sessionStorage.numberOfVisits = numberOfVisits;
    return numberOfVisits;
};

function _closePopin() {
    $popinNewsletter.empty().addClass('d-none');
    $('.popin-modal-background').hide();
}

function showPopinNewsletter() {
    const DELAY = 10000;
    const count = _getNumberOfPreviousVisits();

    if (count > 0) {
        setTimeout(function () {
            $.ajax({
                url: $popinNewsletter.data('show'),
                method: 'get',
                dataType: 'json',
                success: function (data) {
                    const isDidomiVisible = window.didomiOnReady && Didomi.notice.isVisible();

                    if (!isDidomiVisible) {
                        if (data.contentBody) {
                            $('.popin-modal-background').show();
                            $popinNewsletter.attr('data-popin-id', data.popinID)
                                            .html(data.contentBody)
                                            .removeClass('d-none');
                        }
                        Gtm.pushEvent(data.layer);
                    }
                }
            });
        }, DELAY)
    }
};

function closePopinNewsletter(e) {
    if ($('.popin-newsletter_close').is(e.target) ||
        $popinNewsletter.has(e.target).length <= 0) {

        $.ajax({
            url: $popinNewsletter.data('close'),
            type: 'post',
            dataType: 'json',
            success: function (data) {
                Gtm.pushEvent(data.layer);
                _closePopin();
            },
            error: function () {
                _closePopin();
            }
        });
    }
};

function confirmPopinNewsletter() {
    $.ajax({
        url: $popinNewsletter.data('confirm'),
        type: 'post',
        dataType: 'json',
        success: function (data) {
            Gtm.pushEvent(data.layer);
            _closePopin();
        },
        error: function () {
            _closePopin();
        }
    });
};

$(() => {
    showPopinNewsletter();
    $('body')
        .on('click', '.popin-newsletter_close', closePopinNewsletter)
        .on('touchend click', '.popin-modal-background', closePopinNewsletter)
        .on('popin:successSubscribe', confirmPopinNewsletter);
});