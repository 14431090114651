'use strict';

module.exports = (function scrollToAnchor() {
    const currentLocation = new URL(window.location.href);
    let element;

    if (currentLocation.hash) {
        element = document.getElementById(currentLocation.hash.slice(1));
        if (element) {
            element.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
        }
    }
}());
