'use strict';

const validateExtension = function validateExtension() {
    var validFileExtensions = ['.pdf', '.PDF'];
    var fileInputArray = $('.file-upload');
    var wrongExtensionFileNumber = 0;

    for (var i = 0; i < fileInputArray.length; i++) {
        var oInput = fileInputArray[i];
        var sFileName = oInput.value;
        if (sFileName) {
            if (sFileName.length > 0) {
                var blnValid = false;
                oInput.files[0].fileName = sFileName;

                for (var j = 0; j < validFileExtensions.length; j++) {
                    var sCurExtension = validFileExtensions[j];

                    if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() === sCurExtension.toLowerCase()) {
                        blnValid = true;
                        break;
                    }
                }

                if (!blnValid) {
                    var errorEl = $(oInput).next('.invalid-feedback');
                    errorEl.html($(oInput).data('formatError'));
                    errorEl.show();
                    wrongExtensionFileNumber++;
                }
            }
        }
    }

    return !(wrongExtensionFileNumber > 0);
};

// Validate upload file size
const validateFileSize = function validateFileSize() {
    var fileInputArray = $('.file-upload');
    var fileSize;
    var heavyFileNumber = 0;

    for (var i = 0; i < fileInputArray.length; i++) {
        var files = $(fileInputArray[i].files);
        if (files[0]) {
            fileSize = files[0].size / 1024 / 1024;

            if (fileSize > 2) {
                var errorEl = $(fileInputArray[i]).next('.invalid-feedback');
                errorEl.html('File exceeds max size allowed');
                errorEl.show();
                heavyFileNumber++;
            }
        }
    }

    return !(heavyFileNumber > 0);
};

const checkQueryString = function checkQueryString(url) {
    if (url.indexOf('?') !== -1) {
        return true;
    }

    return false;
};

const onChangeCountry = function onChangeCountry(elt) {
    let elId = $(elt).val();
    let contentClass = '.' + elId;
    var $content = $('.customer-service-wrapper');
    var action = $('.page').data('action');
    var localeCode = $('option:selected', elt).attr('locale-code');
    var localeCurrencyCode = $(elt).data('currencycode');
    var queryString = $('.page').data('querystring');
    var url = $(elt).data('url');
    var selectedCountry = $('option:selected', elt).attr('selected-country');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {
            code: localeCode,
            queryString: queryString,
            CurrencyCode: localeCurrencyCode,
            action: action
        },
        success: function (response) {
            if (response && response.savRedirectUrl) {
                var redirectCountry = selectedCountry;
                if (checkQueryString(response.savRedirectUrl)) {
                    if (response.savRedirectUrl.indexOf('country=') !== -1) {
                        response.savRedirectUrl = response.savRedirectUrl.replace(/([?|&]country=)[^&]+/, '$1' + redirectCountry);
                    } else {
                        response.savRedirectUrl = response.savRedirectUrl + '&country=' + redirectCountry;
                    }
                } else {
                    response.savRedirectUrl = response.savRedirectUrl + '?country=' + redirectCountry;
                }

                window.location.href = response.savRedirectUrl; // +redirectCountry;
            }
            $content.show().not(contentClass).hide();
        },
        error: function () {
            console.error('ERROR SOMETHING WENT WRONG');
        }
    });
};

$(function () {
    var $content = $('.customer-service-wrapper');
    var $contentClass = $('.tab-pane.active [name="choose-country"]');
    var optionValue = $('option:selected', $contentClass).val();
    var optionDisplayValue = '.' + optionValue;
    $content.show().not(optionDisplayValue).hide();

    $('[name="choose-country"]').on('change', function (e) {
        e.preventDefault();
        onChangeCountry(this);
    });

    var checked = false;
    $('input[id^=dwfrm_customerService_conditions]').on('click', function () {
        if ($(this).prop('checked') === true) {
            checked = true;
        } else if ($(this).prop('checked') === false) {
            checked = false;
        }
    });

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var x = $(e.target).attr('href');
        if ($(x + ' [name="choose-country"]').length > 0) {
            onChangeCountry($(x + ' [name="choose-country"]'));
        }
    });

    $('.notFrance').on('submit', function (e) {
        $('.invalid-feedback').hide();

        if (checked === false) {
            var errorEl = $('.invalid-checkbox');
            errorEl.html('Please accept the terms and conditions before submitting the form');
            errorEl.show();
            e.preventDefault();
        }

        if (!validateExtension()) {
            e.preventDefault();
        }

        if (!validateFileSize()) {
            e.preventDefault();
        }
    });
});
