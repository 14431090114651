'use strict';
var productBase = require('base/product/base');
const Gtm = require('./gtm');

// Calculate line count based on height and line height
function countLines(element) {
    var lineHeight = parseInt(window.getComputedStyle(element, null).getPropertyValue('line-height'));
    var maxHeight = element.offsetHeight;
    return lineHeight === 0 || isNaN(lineHeight) ? 0 : Math.floor(maxHeight / lineHeight);
}

function initRecoCarousel(carousel) {
    const $carousel = $(carousel);
    const cardsCount = $carousel.find('.card').length;

    if (cardsCount <= 3) {
        $carousel.parent().find('.arrow').hide();
    }

    if ((carousel =='.recommendations-carousel'
        || carousel.classList.contains('recommendations-carousel'))
        && cardsCount < 4
        && ($(window).width() > 992)) {

        return;

    } else if (cardsCount > 0) {
        $carousel.not('.slick-initialized').slick({
            slidesToShow: cardsCount > 2 ? 3 : cardsCount,
            slidesToScroll: 2,
            dots: false,
            arrows: cardsCount > 3,
            prevArrow: $('.slick-arrow-prev'),
            nextArrow: $('.slick-arrow-next'),
            infinite: false,
            responsive: [
                {
                    breakpoint: 992, // tablet breakpoint
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
}

module.exports = {
    clickEventDataLayer: function () {
        $('[data-gtm-event]').on('click', function (event) {
            event.preventDefault();

            const layer = {
                event: this.dataset.gtmEvent,
                eventAction: 'click',
                eventCategory: this.dataset.gtmCategory || '',
                eventLabel: this.dataset.gtmLabel || ''
            };

            if (typeof dataLayer !== 'undefined') {
                dataLayer.push(layer);
            }
            if (this.dataset.href) {
                window.location.href = this.dataset.href;
            }
        });
    },
    textTruncate: function () {
        $(window).on('load', function () {
            var $showMore = $('.show-more-desc');

            if ($showMore.length === 0) {
                return;
            }

            var $prev = $showMore.prev();

            if ($prev.length === 0) {
                return;
            }

            var previousElement = $prev.get(0);
            var maxLine = $prev.data('maxline');
            var classTruncateName = 'truncate-text-' + maxLine

            previousElement.classList.remove(classTruncateName);

            var lineCount = countLines(previousElement);

            if (lineCount > maxLine) {
                $showMore.show();
            }

            previousElement.classList.add(classTruncateName);
        });

        $('body').on('click','.show-more-desc', function() {
            var $prev = $('.show-more-desc').prev();
            var maxLine = $prev.data('maxline');
            var className = 'truncate-text-' + maxLine;
            var showMoreTxt = $(this).data('showmore');
            var showLessTxt = $(this).data('showless');

            $prev.toggleClass(className);
            $(this).html($prev.hasClass(className) ? showMoreTxt : showLessTxt);
        });
    },
    carouselSwipe:  function(){
        $(window).on('load resize',function(){
            if($(window).width() < 992){
                $(".mobile-swipe-carousel").on("touchstart", function(event){
                    var xClick = event.originalEvent.touches[0].pageX;
                    $(this).one("touchmove", function(event){
                        var xMove = event.originalEvent.touches[0].pageX;
                        if( Math.floor(xClick - xMove) > 5 ){
                            $(this).carousel('next');
                        }
                        else if( Math.floor(xClick - xMove) < -5 ){
                            $(this).carousel('prev');
                        }
                    });
                    $(".carousel").on("touchend", function(){
                            $(this).off("touchmove");
                    });
                });
            }
        });
    },
    customAccordian: function(){
        $('.custom-accordian .custom-accordian-card .title').on('click', function (event) {
            $('.custom-accordian-card').not($(this).closest('.custom-accordian-card')).removeClass('active');
        });
    },
    pdpMutationListener: function() {
        const slickObserver = new MutationObserver(mutations => {
            mutations
                .filter(mutation => mutation.addedNodes && mutation.addedNodes.length > 0)
                .forEach(mutation => {
                    $(mutation.addedNodes)
                        .find('.recommendations-carousel')
                        .get()
                        .forEach(carousel => {
                            initRecoCarousel(carousel);
                        });
                });
        });
        slickObserver.observe(document.body, { childList: true, subtree: true });
    },
    pdpRecommSlider : function() {
        initRecoCarousel('.recommendations-carousel');
    },
    hpMutationListener: function() {
        const slickObserver = new MutationObserver(function (mutations) {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                    const carousels = $(mutation.addedNodes).find('.recommendations-hp');
                    for (let i = 0, len = carousels.length; i < len; i++) {
                        $(carousels[i]).not('.slick-initialized').slick({
                            slidesToShow: 4, // default desktop values
                            slidesToScroll: 1,
                            dots: false,
                            arrows: true,
                            prevArrow: $('.slick-arrow-prev'),
                            nextArrow: $('.slick-arrow-next'),
                            infinite: false,
                            variableWidth: true,
                            responsive: [
                                {
                                    breakpoint: 992, // tablet breakpoint
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        });
        slickObserver.observe(document.body, { childList: true, subtree: true });
    },
    hpRecommSlider : function(){
        $('.recommendations-hp').not('.slick-initialized').slick({
            slidesToShow: 4, // default desktop values
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            prevArrow: $('.slick-arrow-prev'),
            nextArrow: $('.slick-arrow-next'),
            infinite: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 992, // tablet breakpoint
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    },
    hpCatMutationListener: function() {
        const slickObserver = new MutationObserver(function (mutations) {
            mutations.forEach((mutation) => {
                if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                    const carousels = $(mutation.addedNodes).find('.categories-hp');
                    for (let i = 0, len = carousels.length; i < len; i++) {
                        $(carousels[i]).not('.slick-initialized').slick({
                            slidesToShow: 6, // default desktop values
                            slidesToScroll: 1,
                            dots: false,
                            arrows: true,
                            prevArrow: $('.slick-arrow-prev'),
                            nextArrow: $('.slick-arrow-next'),
                            infinite: false,
                            variableWidth: true,
                            touchThreshold: 10,
                            responsive: [
                                {
                                    breakpoint: 992, // tablet breakpoint
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        });
        slickObserver.observe(document.body, { childList: true, subtree: true });
    },
    hpCatRecommSlider : function(){
        const $slider = $('.categories-hp');
        let slidesToShow = 6;
        if ($slider.find('.category-item').length < slidesToShow) {
            slidesToShow = $slider.find('.category-item').length;
        };

        $slider.not('.slick-initialized').slick({
            slidesToShow: slidesToShow, // default desktop values
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            prevArrow: $('.slick-arrow-prev'),
            nextArrow: $('.slick-arrow-next'),
            infinite: false,
            variableWidth: false,
            touchThreshold: 10,
            responsive: [
                {
                    breakpoint: 992, // tablet breakpoint
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        variableWidth: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 768, // Mobile breakpoint
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                        variableWidth: true,
                        arrows: false
                    }
                }
            ]
        });

        const windowWidth = $(window).width();
        if (windowWidth < 1194 && windowWidth > 768) {
            $slider.find('.slick-track').css('max-width', windowWidth);
        }
    },
    tipsSlider : function(){
        const $slider = $('.imgSlider');
        let slidesToShow = 2;
     

        $slider.not('.slick-initialized').slick({
            slidesToShow: slidesToShow, // default desktop values
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            infinite: false,
            variableWidth: false,
            touchThreshold: 10,
            responsive: [
                {
                    breakpoint: 576, // Mobile breakpoint
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

        const windowWidth = $(window).width();
        if (windowWidth < 1194 && windowWidth > 768) {
            $slider.find('.slick-track').css('max-width', windowWidth);
        }
    },
    searchProductSlider : function(){
        $('.card-slider').not('.slick-initialized').slick({
            slidesToShow: 1, // default desktop values
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            prevArrow: $('.slick-arrow-prev'),
            nextArrow: $('.slick-arrow-next'),
            infinite: false,
            responsive: [
                {
                    breakpoint: 992, // tablet breakpoint
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        vertical: true,
                    }
                }
            ]
        });
    },
    tileAddtoCart: function(){
        $(document).on('click', 'button.tile-add-to-cart', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var $parentContainer = $(this).closest('.recomm-card');

            $('body').trigger('product:beforeAddToCart', this);

            pid = $(this).attr('data-pid');

            addToCartUrl = $parentContainer.find('.add-to-cart-url').val();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                quantity: 1
            };

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        Gtm.pushEvent(data.layer);

                        $('.minicart').trigger('count:update', data);
                        // show add to cart toast
                        if (data.newBonusDiscountLineItem
                            && Object.keys(data.newBonusDiscountLineItem).length !== 0) {
                            chooseBonusProducts(data.newBonusDiscountLineItem);
                        }

                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                        productBase.miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    searchBlogSlider : function(){
        $('.search-blog-slider').not('.slick-initialized').slick({
            slidesToShow: 3.2, // default desktop values
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            infinite: false,
            responsive: [
                {
                    breakpoint: 992, // tablet breakpoint
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 0,
                        vertical: true,
                    }
                }
            ]
        });
    },
    showMoreCategories: function () {
        $('.js-toggle-filter-categorie').on('click', function () {
            $(this).toggleClass('filters-expanded');
            $('.blog-list-subcategories-content').toggleClass('d-none');
        })
    },
};
