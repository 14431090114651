'use strict';

var $window = $(window);
var $mainContent = $('#maincontent');
var $dropDownMenu = $('.nav-item>.dropdown-menu');
const $body = $('body');

const menuFlyoutPosition = function menuFlyoutPosition(upScroll) {
    var $header = document.querySelector('#header');
    var headerTop = $header.getBoundingClientRect().top;
    var headerHeight = $header.getBoundingClientRect().height - 2;
    if ($window.width() > 992) {
        if (upScroll) {
            headerTop = 0;
            $dropDownMenu.css({
                top: headerTop + headerHeight
            });
            $mainContent.css({
                top: headerTop + headerHeight,
                position: 'relative',
                'margin-bottom': headerTop + headerHeight
            });
        } else {
            if (headerTop < 0) {
                $dropDownMenu.css({ top: '-2px' });
            } else {
                $dropDownMenu.css({ top: headerTop + headerHeight });
            }
            $mainContent.css({
                top: '0px',
                'margin-bottom': '0'
            });
        }
    } else {
        $dropDownMenu.css({ top: '' });
        $mainContent.css({
            top: headerHeight
        });
    }

    $('.suggestions-wrapper').css({
        top: headerTop + headerHeight + 2,
        'max-height': $window.height() - headerHeight
    });
};

const hamburgerContainerHeight = function hamburgerContainerHeight() {
    var windowHeight = $window.height();
    var secondaryNavHeight = $('.secondary-ham-nav').height();
    var closeMenuHeight = $('.close-menu').height();
    var navMenuHeight = $('.navbar-nav').height();
    var containerHeight = 0;
    var calculateMenu = 0;

    containerHeight = windowHeight - (secondaryNavHeight + closeMenuHeight);
    calculateMenu = windowHeight - (navMenuHeight + secondaryNavHeight + closeMenuHeight);
    var newHeight = windowHeight - secondaryNavHeight;

    $('.main-dropdown-menu').css({ height: containerHeight });
    if ($window.width() < 992 && calculateMenu < 0) {
        // Paysage orientation
        if (window.orientation != 0) {
            $('.navbar-expand-lg').css({
                height: newHeight,
                'overflow-x': 'auto'
            });
        }
    } else {
        $('.navbar-expand-lg').css({
            height: '',
            'overflow-x': ''
        });
    }
};

const localeContainerHeight = function localeContainerHeight() {
    var windowHeight = $window.height();
    var secondaryNavHeight = $('.secondary-ham-nav').height();
    var closeMenuHeight = $('.close-menu').height();
    var containerHeight = 0;

    containerHeight = windowHeight - (secondaryNavHeight + closeMenuHeight);
    $('.secondary-ham-nav .country-selector.show').css({ height: containerHeight });
};

/* @function: populateNavBannerData
*@description : populates the banner content on hover
*@params : $elem : jquery element , data : object in string format*/
const populateNavBannerData = function populateNavBannerData($elem, data) {
    if (data.length) {
        var $container = $elem.closest('.main-dropdown-menu').find('.category-img-banner');
        var $releventContainer = $elem.closest('.main-dropdown-menu').find('.category-img-banner[data-category-id="' + data + '"]');
        var imgURL = $releventContainer.find('div.col-12').attr('data-background-url');
        $releventContainer.css('background-image', 'url(' + imgURL + ')');
        $container.addClass('d-none');
        $releventContainer.removeClass('d-none');
    }
};

/**
 * The function `closeLocaleSelector` sends an AJAX post request to a specified URL and hides the
 * parent element with class 'locale-selector' on success.
 */
function closeLocaleSelector() {
    var $btn = $(this);
    $.ajax({
        url: $btn.data('url'),
        type: 'post',
        dataType: 'json',
        success: function () {
            $btn.parents('.locale-selector').hide(500 , function() {
                menuFlyoutPosition(true);
             });
        }
    });
}

module.exports = {
    /* @function: pinnedHeader
    *@description : check the scroll position and pins or hides header */
    pinnedHeader: function () {
        var lastScrollPos = 0;
        var currentScrollPos = 0;
        var scrollDiff = 10;
        var $header = $('#header');
        var headerHeight = $header.height();
        var $subMenu = $('#subMenuSticky.sticky-menu'); // Submenu Page Designer component

        $window.on('load resize', function () {
            if ($('.container.data-checkout-stage').length) {
                if ($window.width() < 992) {
                    $header.addClass('d-none');
                } else {
                    $header.removeClass('d-none');
                }
            }

            $header.addClass('position-fixed');
            if ($('.cart-summary').length) $body.css({ 'padding-top': $header.height() });
        });

        $('.cart-more-header-down').on('click', function () {
            if ($('.cart-header-details').hasClass('slide-down')) {
                $('.cart-header-details').removeClass('slide-down').slideUp();
            } else {
                $('.cart-header-details').addClass('slide-down').slideDown();
            }
        });

        $('.cart-more-header-up').on('click', function () {
            $('.cart-header-details').removeClass('slide-down').slideUp();
        });

        $('.summary-toggle-link-bottom').on('click', function () {
            $('.summary-cont .summary-list.small-list-items').slideToggle();
            $(this).toggleClass('shown');
        });

        // avoid the first scroll after refresh page.
        let first = true;
        let $stickyMenuHeader = $('.header_sticky-menu').find('.js-sticky-menu');
        let $stickyMobileMenuHeader = $('.product-menu__mobile');
        var targetScrollPos = headerHeight + ($('.product-wrapper').outerHeight() || 0);

        $window.scroll(function () {
            // test first time to avoid first scroll after init
            if (!first) {
                if ($('.cart.cart-page').length || $('.container.cart-empty').length || $('.cart-page.data-checkout-stage').length) return;
                currentScrollPos = $(this).scrollTop();
                if ($('.search-shown').length) {
                    $('.reset-button').trigger('click');
                }

                if (Math.abs(lastScrollPos - currentScrollPos) <= scrollDiff) {
                    return;
                }

                if (currentScrollPos < lastScrollPos) {
                    // scroll up
                    $header.css({ top: '0px' });
                    $subMenu.removeClass('d-none');
                    $subMenu.css({ top: $header.outerHeight() + 'px' });
                    menuFlyoutPosition(true);
                    if ($stickyMenuHeader.hasClass('sticky-menu')) {
                        $stickyMenuHeader.css({ top: $header.height() });
                        $stickyMobileMenuHeader.css({ top: $header.height() });
                    }
                } else if ($window.scrollTop() > 0 && ($window[0].innerHeight + $window[0].window.scrollY) < (document.body.scrollHeight - headerHeight)) { // scroll down // check scrolltop to avoid IOS bounce // condition to avoid bottom bounce (due to PinnedHeader method)
                    $header.css({ top: -headerHeight });
                    $subMenu.addClass('d-none');
                    menuFlyoutPosition(false);
                    $stickyMenuHeader.addClass('sticky-menu');
                    $stickyMenuHeader.css({ top: '0px' });
                    $stickyMobileMenuHeader.css({ top: '0px' });
                }
            } else {
                first = false;
            }

            lastScrollPos = currentScrollPos;
            var $isScrollPast = currentScrollPos >= targetScrollPos;
            $stickyMenuHeader.toggleClass('show', $isScrollPast);
            $stickyMobileMenuHeader.toggleClass('show', $isScrollPast);
        });
    },
    init: function () {
        var $header = document.querySelector('#header');
        var headerTop = $header.getBoundingClientRect().top;
        var headerHeight = $header.getBoundingClientRect().height - 2;

        $body.on('click', '[data-event-click="skiptomain"]', () => {
            window.location.href = '#maincontent';
        });

        $body.on('click', '[data-event-click="skiptofooter"]', () => {
            window.location.href = '#footercontent';
        });

        $body.on('click', '[data-event-click="account"]', (e) => {
            window.location.href = atob(e.currentTarget.dataset.url);
        });

        $body.on('click', '[data-event-click="stores"]', (e) => {
            window.location.href = atob(e.currentTarget.dataset.url);
        });

        if ($('.container.cart-empty').length || $('.cart-page.data-checkout-stage').length) {
            return;
        }

        if (!$('.basket-page').length) {
            if ($window.width() > 992) {
                $mainContent.css({
                    top: headerTop + headerHeight,
                    'margin-bottom': headerTop + headerHeight
                });
                $dropDownMenu.css({
                    top: headerTop + headerHeight
                });
            } else {
                $mainContent.css({
                    top: headerTop + headerHeight,
                    'margin-bottom': headerTop + headerHeight
                });
            }
        }

        $('.search-logo-cont').css('width', $('.main-logo-container').outerWidth());

        // menuFlyoutPosition(true);
        $window.on('resize', function () {
            $('.search-logo-cont').css('width', $('.main-logo-container').outerWidth());
            menuFlyoutPosition(true);
            if ($('.multilevel-dropdown').hasClass('in')) {
                if (window.innerWidth < 992) {
                    hamburgerContainerHeight();
                } else {
                    $('.close-button button').click();
                    $('.menu-container').addClass('hidden-md-down');
                    $('.nav-link[aria-expanded="true"]').attr('aria-expanded', false);
                }
            }

            if ($('.country-selector.show').length && window.innerWidth < 992) {
                localeContainerHeight();
            }
        });

        $('[data-event-hover="SubCategorySolutions"]').on('mouseenter', function () {
            if ($(this).attr('data-cid')) {
                $('[data-ui="navTemplateRight"][data-cid="' + $(this).attr('data-cid') + '"]').parent().find('.dropdown-menu--right').removeClass('visible');
                $('.js-subcategory').find('[data-ui="navTemplateRight"][data-cid="' + $(this).attr('data-cid') + '"]').addClass('visible');
            }
        });

        $('.dropdown-menu .dropdown-menu.applications a').on('mouseenter', function () {
            if ($(this).attr('data-tiles-data')) {
                populateNavBannerData($(this), $(this).attr('data-tiles-data'));
            }
        });

        $('.navbar-toggler').on('click', function () {
            $('.menu-container').removeClass('hidden-md-down');
            $body.css({ overflow: 'hidden' });
            hamburgerContainerHeight();
        });

        $('body,.dropdown-menu').on('click', '.close-button button', function () {
            $('.menu-container').addClass('hidden-md-down');
            $body.css({ overflow: 'unset' });
        });

        $('.main-dropdown-menu .overlay').on('mouseenter', function () {
            $('.main-dropdown-menu').removeClass('show');
            $('.nav-link[aria-expanded="true"]').attr('aria-expanded', false);
            $('.nav-item.dropdown').removeClass('show');
        });

        // Mobile Locale Selector
        $('.mobile-country-selector').on('click', function () {
            $('.back-link, .top-category').hide();
            $('.print-locale').html($('.current-locale').html());
            localeContainerHeight();

            // Bind Close Code
            $('.close-arrow').on('click', function () {
                $('.country-selector.show').removeClass('show');
                $('.back-link, .top-category').show();
                $('.print-locale').html('');
                $('.nav-menu').remove();
                $('.secondary-ham-nav .country-selector').css({ height: 'auto' });
            });

            $('.back-button').on('click', function () {
                $('.close-arrow').trigger('click');
            });

            $('.close-button').on('click', function () {
                $('.close-arrow').trigger('click');
            });
        });

        $body.on('click', '.search-btn-container', function () {
            $('.header.container-fluid').addClass('dark-bg');
            $('.main-search-container').addClass('active');
            $('.search-list-container').css({ 'max-height': $('.right-col').height() });
        });

        $body.on('click', '.main-search-container .reset-button', function () {
            $('.main-search-container').removeClass('active');
            $('.header.container-fluid').removeClass('dark-bg');
        });

        $body.on('click', '.popin-locale_close', closeLocaleSelector);
    }
};
