'use strict';

/**
 * @enum {String}
 */
const NewOptinSource = {
    HOME: 'home_page',
    FOOTER: 'footer',
    POPIN: 'popin'
}

class Gtm {

    static get NEW_OPTIN_SOURCE() {
        return NewOptinSource;
    }

    static pushEvent(event) {
        if (typeof dataLayer === 'undefined') {
            return;
        }

        if (event === null || event === undefined) {
            return;
        }

        if (Array.isArray(event)) {
            event.forEach(e => dataLayer.push(e));
        } else if (typeof event === 'object') {
            dataLayer.push(event);
        }
    }

    /**
     * @param {NewOptinSource} source 
     */
    static eventNewOptin(source) {
        Gtm.pushEvent({
            event: 'new_optin',
            source: source
        });
    }
}

module.exports = Gtm;
