'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;

const updateDisplay = function updateDisplay() {
    var countData = $('.minicart .minicart-quantity').data('quantity');
    var count = parseInt(countData, 10);
    $('.minicart .minicart-quantity-inner').text(count);
    $('.minicart .minicart-quantity-mobile').text(count);
};

const reloadMiniCart = function reloadMiniCart() {
    $('.minicart .popover').spinner().start();
    $('.minicart .popover').addClass('show');
    $('body').find('.cart-modal-background').addClass('d-block');

    $.ajax({
        url: $('.minicart').data('action-url'),
        method: 'GET',
        success: function (data) {
            $('.minicart .popover').empty();
            $('.minicart .popover').append(data);
            $('.cart-action-items').css('height', $('.navbar-header').height());
            updateDisplay();
            updateMiniCart = false;
            $.spinner().stop();
        },
        error: function () {
            $('.minicart').trigger('minicart:close');
            $.spinner().stop();
        }
    });
};

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('touchend click', function (e) {
        if ($('.minicart .minicart-total').has(e.target).length <= 0){
            return;
        }
        e.preventDefault();

        if ($('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                $('body').find('.cart-modal-background').addClass('d-block');
                updateDisplay();
            } else {
                reloadMiniCart();
            }
        }
    });

    $('body').on('touchend click minicart:close', function (e) {
        if ($('.minicart').is(e.target) ||
        $('.minicart').has(e.target).length <= 0 ||
        $('.top-cart-text').has(e.target).length ||
        $('.close-button').has(e.target).length) {
            $('.minicart .popover').removeClass('show');
            $('body').find('.cart-modal-background').removeClass('d-block');
        }
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
        if ($('.cart.cart-page').length || $('.container.cart-empty').length) {
            location.reload();
        }
    });

    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });

    $('body').on('minicart:afterAddToCart', () => {
        reloadMiniCart();
        setTimeout(() => $('.minicart').trigger('minicart:close'), 5000);
    });

    $('.minicart').on('click', '.tile-add-to-cart', () => {
        setTimeout(() => reloadMiniCart(), 500);
    });
};
