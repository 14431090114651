'use strict';

window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(function () {
    processInclude(require('./components/footer'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/obfuscation'));
    processInclude(require('./components/header'));
    processInclude(require('./components/utilityFunctions'));
    processInclude(require('./contact/contact'));
    processInclude(require('./customerSupport/customerService'));
    processInclude(require('./components/popinNewsletter'));
    require('./scrollToAnchor');
});

require('slick-carousel');
require('imagesloaded');
require('./thirdParty/tiny-slider');
require('base/thirdParty/bootstrap');
require('./components/spinner');
processInclude(require('base/components/menu'));
