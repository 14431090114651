'use strict';

const Gtm = require('../components/gtm');

/**
 * Validate upload file extension
 * @returns {boolean} return true if extension is ok
 */
function validateExtension() {
    var validFileExtensions = ['.pdf', '.PDF'];
    var fileInputArray = $('.file-upload');
    var wrongExtensionFileNumber = 0;

    for (var i = 0; i < fileInputArray.length; i++) {
        var oInput = fileInputArray[i];

        var sFileName = oInput.value;
        if (sFileName.length > 0) {
            var blnValid = false;
            oInput.files[0].fileName = sFileName;

            for (var j = 0; j < validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];

                if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() === sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }

            if (!blnValid) {
                var errorEl = $(oInput).next('.invalid-feedback');
                errorEl.html('Sorry, this format is invalid, allowed extensions are: ' + validFileExtensions.join(', '));
                errorEl.show();
                wrongExtensionFileNumber++;
            }
        }
    }

    return wrongExtensionFileNumber === 0;
}

// Validate upload file size
function validateFileSize() {
    var fileInputArray = $('.file-upload');
    var fileSize;
    var heavyFileNumber = 0;

    for (var i = 0; i < fileInputArray.length; i++) {
        var files = $(fileInputArray[i].files);
        fileSize = files[0].size / 1024 / 1024;

        if (fileSize > 5) {
            var errorEl = $(fileInputArray[i]).next('.invalid-feedback');
            errorEl.html('File exceeds max size allowed');
            errorEl.show();
            heavyFileNumber++;
        }
    }

    return heavyFileNumber === 0;
}

function submitCreateQuoteForm(e) {
    e.preventDefault();
    let $form = $(this);
    // eslint-disable-next-line no-undef
    grecaptcha.enterprise.execute(grecaptcha.siteKey, { action: 'contact_us' }).then(function (token) {
        let $errorCapthca = $form.find('.error-captcha');
        $errorCapthca.hide();
        let dataForm = $form.serializeArray();
        dataForm.push({
            name: 'confirmationCid',
            value: $form.data('confirmationCid')
        });

        dataForm.push({
            name: 'g-recaptcha-response',
            value: token
        });

        $.ajax({
            url: $form.attr('action'),
            type: 'post',
            data: dataForm,
            success: function (data) {
                if (data.success) {
                    $form.parents('.contact-us-quote-container').empty().html(data.messageHtml);
                    Gtm.pushEvent(data.layer);
                } else if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else if (data.error && data.message) {
                    $errorCapthca.empty().html(data.message);
                    $errorCapthca.css('display', 'block');
                }
            }
        });
    });
}

function submitCallBackForm(e) {
    e.preventDefault();
    let $form = $(this);
    // eslint-disable-next-line no-undef
    grecaptcha.enterprise.execute(grecaptcha.siteKey, { action: 'contact_callback' }).then(function (token) {
        let $errorCapthca = $form.find('.error-captcha');
        $errorCapthca.hide();
        let dataForm = $form.serializeArray();
        dataForm.push({
            name: 'confirmationCid',
            value: $form.data('confirmationCid')
        });

        dataForm.push({
            name: 'g-recaptcha-response',
            value: token
        });

        $.ajax({
            url: $form.attr('action'),
            type: 'post',
            data: dataForm,
            success: function (data) {
                if (data.success) {
                    $form.parents('.call-back-form-container').empty().html(data.messageHtml);
                    Gtm.pushEvent(data.layer);
                } else if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else if (data.error && data.message) {
                    $errorCapthca.empty().html(data.message);
                    $errorCapthca.css('display', 'block');
                }
            }
        });
    });
};

function getCookie(cname) {
    let name = cname + '=';
    let ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function contactDownloadSubmited() {
    const confirmationElement = document.getElementById('contactConfirmation');
    const formElement = document.getElementById('formContactDownload');

    if (confirmationElement && formElement) {
        confirmationElement.classList.add('contact-confirmed');
        formElement.classList.add('form-send');
    }
}

/**
 * @param {Event} event
 */
function submitCreateStudies(event) {
    event.preventDefault();
    const $form = $(this);
    const data = $form.serializeArray();

    $.ajax({
        url: $form.attr('action'),
        type: $form.attr('method'),
        data: data,
        success: (response) => {
            if (response.success) {
                contactDownloadSubmited();
            }
            if (response.layer) {
                dataLayer.push(response.layer);
            }
        }
    });
}

/**
 * @param {HTMLSelectElement} selector
 * @returns {HTMLSelectElement}
 */
function clearOptions(selector) {
    let selectParentNode = selector.parentNode;
    let newSelectObj = selector.cloneNode(false); // Make a shallow copy
    selectParentNode.replaceChild(newSelectObj, selector);
    return newSelectObj;
}

/**
 * @param {HTMLSelectElement} selectorRegions
 * @param {String[]} regions
 */
function fillSelector(selectorRegions, regions) {
    let fragment = document.createDocumentFragment();

    selectorRegions = clearOptions(selectorRegions);
    regions.forEach((region) => {
        let option = document.createElement('option');

        option.innerHTML = region;
        option.value = region;
        fragment.appendChild(option);
    });
    selectorRegions.appendChild(fragment);
}

function displaySelectorRegions() {
    const contactFormRegionClass = 'contact-form-region';
    const containerSelectorRegions = document.getElementById('contactFormRegionsSelector');
    const ElmSelectRegion = document.getElementById('dwfrm_contactus_region');
    const rowElement = containerSelectorRegions.parentElement;

    containerSelectorRegions.classList.remove(contactFormRegionClass);
    for (let i = 0; i < rowElement.children.length; i++) {
        const element = rowElement.children.item(i);

        element.classList.replace('col-md-6', 'col-md-4');
    }

    ElmSelectRegion.setAttribute('aria-required', 'true');
    ElmSelectRegion.required = true;
}

function hideSelectorRegions() {
    const contactFormRegionClass = 'contact-form-region';
    const containerSelectorRegions = document.getElementById('contactFormRegionsSelector');
    const ElmSelectRegion = document.getElementById('dwfrm_contactus_region');
    const rowElement = containerSelectorRegions.parentElement;

    containerSelectorRegions.classList.add(contactFormRegionClass);
    for (let i = 0; i < rowElement.children.length; i++) {
        const element = rowElement.children.item(i);

        element.classList.replace('col-md-4', 'col-md-6');
    }

    ElmSelectRegion.setAttribute('aria-required', 'false');
    ElmSelectRegion.required = false;

}

/**
 * @param {HTMLSelectElement} selectorCountry The country selector in the contact us form
 * @returns {Void}
 */
function initRegions(selectorCountry) {
    if (typeof getAllRegions !== 'function' && selectorCountry === undefined) {
        return;
    }
    const allRegions = getAllRegions();
    const selectorRegions = document.getElementById('dwfrm_contactus_region');

    if (selectorRegions === null && typeof allRegions !== 'object' && selectorCountry.value === '') {
        return;
    }
    if (selectorCountry.value in allRegions) {
        fillSelector(selectorRegions, allRegions[selectorCountry.value].regions);
        displaySelectorRegions();
    } else {
        clearOptions(selectorRegions);
        hideSelectorRegions();
    }
}

$(function() {
    var $selectService = $('.choose-service'),
        $selectTheme = $('#dwfrm_contactus_theme'),
        $selectCountry = $('#dwfrm_contactus_country'),
        $quoteDepartmentBlock = $('.quote-department-block'),
        $department = $('#dwfrm_contactus_department_service_department'),
        $selectDepartment = $('.choose-department'),
        $contactSales = $('.contact-us-quote-page'),
        $content = $('.contact-content-wrapper'),
        $SalesSelectedDepartment = $('option:selected', $selectDepartment).val();

    initRegions($selectCountry.get(0));
    // Set hidden inputs to send values to Service Cloud
    if ($contactSales.length) {
        $('#dwfrm_contactus_myquestion_service_sales').val('service_sales');
        $('#dwfrm_contactus_department_service_department').val($SalesSelectedDepartment);
    }

    if (getCookie('dw_b2b_casestudies') === '1') {
        contactDownloadSubmited();
    }

    $selectService.on('change', function () {
        let elId = $(this).val(),
            $case = $('#dwfrm_contactus_myquestion_' + elId),
            contentId = '#' + elId;

        $content.show().not(contentId).hide();
        $case.val(elId);
    });

    $selectCountry.on('change', function () {
        let countryId = $(this).find('option:selected').attr('id');

        initRegions(this);
        if (countryId !== 'country_france') {
            $quoteDepartmentBlock.addClass('d-none');
            $department.val(countryId || 'none');
        } else {
            $quoteDepartmentBlock.removeClass('d-none');
            $selectDepartment.trigger('change');
        }
    });

    $selectDepartment.on('change', function () {
        let elId = $(this).val();

        $department.val(elId);
    });

    // Show and hide forms
    $selectTheme.on('change', function () {
        if ($(this).val() === 'service_eShop_return') {
            $('.fieldset-wrapper').hide();
            $('.returns-wrapper').show();
        } else {
            $('.fieldset-wrapper').show();
            $('.returns-wrapper').hide();
        }
    });

    // Reset invalid feedback on file upload
    $('.file-upload').on('change', function () {
        $('.invalid-feedback').hide();
    });

    $('body')
        .on('submit', 'form.create-studies', submitCreateStudies)
        .on('submit', 'form.create-quote', submitCreateQuoteForm)
        .on('submit', 'form.call-back', submitCallBackForm);
});
